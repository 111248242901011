@import '../../scss/variables';

@keyframes transition-in {
  0% {
    opacity: 0;
  }
  0% {
    opacity: 1;
    transform: translate(-50%, -100%);
  }
}

.input {
  position: relative;
  z-index: 2;
}

input,
select {
  font-size: 1rem;
  color: #333;
  box-sizing: border-box;
}

select,
input[type=text],
input[type=number] {
  appearance: none;
  border: 1px solid #ddd;
  padding: .625rem .375rem;
  border-radius: .1875rem;
  background-color: #fff;
  font-size: 1rem;
  font-family: $font;
  box-sizing: border-box;
  transition: all .2s ease-out;
  outline: none;
  height: 2.5rem;

  &:focus {
    border-width: .125rem;
    border-color: $blue;
    box-shadow: 0 0 .125rem .25rem rgba($blue,.1);
  }

  &.invalid {
    border-color: $red;
    border-width: .125rem;
    box-shadow: 0 0 .125rem .25rem rgba($red,.1);
  }

  &:focus {

  }
}

input[type=number] {
  width: 10reem;
}

select {
  padding-right: 2rem;
  background-image: url(/images/arrow_drop_down-24px.svg);
  background-repeat: no-repeat;
  background-position: center right;
  min-width: 10.9375rem;
  box-shadow: 0 0.0625rem .125rem 0 rgba(0,0,0,0.15);
  cursor: pointer;
  transition: background-color .2s ease-out;

  &:hover {
    background-color: rgba(0,0,0,.02);
  }

  &::-ms-expand {
    display: none;
  }
}

.invalid-message {
  background-color: white;
  color: $black;
  position: absolute;
  white-space: nowrap;
  // border: $border;
  padding: .5rem .75rem;
  border-radius: .35rem;
  box-shadow: 0 .25rem .75rem rgba(0,0,0,.1);
  z-index: 2000;
  animation-name: transition-in;
  animation-duration: .3s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  top: -1.25rem;
  left: 50%;
  transform: translate(-50%, -100%);
  filter: drop-shadow(0px 0px 1px rgba(0,0,0,.3));

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: .35rem solid transparent;
    border-right: .35rem solid transparent;
    border-top: .35rem solid white;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
  }

  &-left {
    top: 50%;
    left: -.5rem;
    transform: translate(-100%, -50%);

    &:after {
      border-bottom: .35rem solid transparent;
      border-top: .35rem solid transparent;
      border-left: .4rem solid white;
      border-right: none;
      right: -.375px;
      left: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.invalid-message-enter,
.invalid-message-appear {
  opacity: 0;
  transform: translate(-50%, -150%);
}
.invalid-message-enter-active,
.invalid-message-appear-active {
  opacity: 1;
  transform: translate(-50%, -100%);
  transition: all .25s ease-out;
}
.invalid-message-exit {
  opacity: 1;
  transform: translate(-50%, -100%);
}
.invalid-message-exit-active {
  opacity: 0;
  transform: translate(-50%, -150%);
  transition: all .25s ease-out;
}
