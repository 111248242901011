@import '../../scss/variables';

.toggle {
  width: 32px;
  height: 18px;
  background-color: rgba(0,0,0,.2);
  border-radius: 48px;
  position: relative;
  transition: background-color .2s;

  &-handle {
    height: 12px;
    width: 12px;
    position: absolute;
    border-radius: 16px;
    background-color: white;
    top: 3px;
    left: 3px;
    transition: left .2s;
    // border: 1px solid rgba(0,0,0,.2);
    box-sizing: border-box;
  }

  &.on {
    background-color: $red;

    .toggle-handle {
      left: 17px;
    }
  }
  input {
    display: none;
  }
}
