@import '../../scss/variables';

@keyframes slide-up {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.welcome {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(255,255,255,.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 50vw;
  max-width: 55rem;
  min-width: 30rem;
  background-color: white;
  border: $border;
  border-radius: 1rem;
  padding: 2rem 4rem 4rem;;
  box-shadow: $shadow-large;
  font-size: 1rem;
  animation-name: slide-up;
  animation-iteration-count: 1;
  animation-duration: .35s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-delay: .5s;

  .copy {
    font-size: 1.2rem;
    line-height: 1.35;
  }
}

.buttons {
  display: flex;
  margin-top: 2rem;

  button {
    font-size: 1rem;
    padding: 0 2rem 0 1.5rem !important;
    height: 3rem;
    margin-right: 1rem;

    svg {
      margin-right: .25rem;
    }
  }
}
