@import '../../scss/variables';

.header {
  background-color: #fff;
  box-shadow: 0 .25rem .5rem rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(0,0,0,.075);
  padding: 0 0 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 4000;
  height: 4rem;

  small {
    font-weight: normal;
    color: $gray;
  }

  a {
    font-weight: 600;
    font-size: 1rem;
    height: 100%;
    transition: all .2s ease-out;
  }

  .utils,
  .navigation {
    height: inherit;
    ul,li {
      height: inherit;
    }
  }
}

.utils {
  margin-right: 1rem;

  li {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}


.header a,
.tabset a,
button {
  text-decoration: none;
  color: $black;
  line-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // transition: color .4s, background .4s;
  border: none;
  outline: 0;
  background: none;
  font-size: 0.875rem;
  font-family: $font;
  box-sizing: border-box;
  cursor: pointer;
}
ul {
  display: flex;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  li {
    list-style: none;
    list-style-image: none;
    margin-right: 1rem;
    height: 2rem;
    display: flex;
    align-items: center;
    position: relative;

    img.logo {
      height: 50%;
      margin-right: 1rem;
    }

    .tabset & {
      margin-right: 0;
    }

    a {
      transition: all .2s ease-out;
      box-sizing: border-box;
      height: 2.5rem;

      &:hover {
        color: $red;
      }

      &.selected {
        border-bottom: 2px solid $red;
        color: $red;
      }

      .tabset & {
        font-weight: normal;
        padding: 0 .75rem;
        white-space: nowrap;

        &.selected {
          border: none;
          background-color: rgba($red,.1);
          border-radius: 2rem;
          color: $red;
        }
      }
    }
  }
}

.tabset {
  background-color: lighten($header,1%);
  padding: 2rem 0 1rem;
  width: 100%;
  position: relative;

  &-sticky {
    position: sticky;
    top: -1rem;
    z-index: 3000;
  }

  h1 {
    margin-left: 2rem;
    margin-bottom: .75rem;
    margin-top: .75rem;

    &:after {
      display: none;
    }
  }

  ul {
    padding: .5rem 2rem 0;

    li:first-child {
      a {
        box-sizing: border-box;
        border-left: 1px solid transparent !important;
      }
      a:not(.selected) {
        border-left: 1px solid rgba($black,.2) !important;
      }
    }
  }
}

.error-panel {
  position: fixed;
  top: 4.25rem;
  // right: 8px;
  width: 25rem;
  transform: translateX(-50%);
  // border: $border;
  margin-top: -.5rem;
  color: $gray;
  bottom: .5rem;
  z-index: 9999;
  filter: drop-shadow($shadow-large);
  overflow-y: scroll;

  &-scroll {
    background-color: white;
    border-radius: .5rem;
    // border: $border;
    padding: .75rem .75rem;
  }

  a {
    font-size: 0.875rem;
    font-weight: normal;
    display: block;
    color: $gray;
    margin: 0;
    border-radius: 0.375rem;
    padding: .75rem .75rem;
    line-height: 1.25rem;

    &:hover {
      background-color: lighten($header,2%);
      color: $gray;

      .error-panel-row-icon svg {
        fill: $black;
      }

      .error-panel-row-reset svg {
        fill: $gray;
      }

      .error-panel-row {
        div {
          &:nth-child(1) {
            // color: $red;
          }
        }
      }
    }
  }
  &-heading {
    // border-bottom: 1px solid rgba(0,0,0,.1);
    padding: .5rem .75rem;
    margin-bottom: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      fill: $gray;
      cursor: pointer;
      padding-right: 0;
      transition: fill .2s ease-out;
    }

    button {
      margin-right: -.5rem;
      padding: 0 .5rem !important;

      &:hover {
        svg {
          fill: $red;
        }
      }
    }
  }
  &-heading,
  &-row {

      h3 {
        margin-top: 0;
        margin-bottom: .5rem;
        color: $black;
        &:after {
          display: none;
        }
      }

      small {
        font-size: 0.875rem;
      }
  }

  &-description {
    font-size: 0.875rem;
    color: $gray;
  }

  &-row {
    font-size: 0.875rem;
    display: flex;
    align-items: flex-start;
    position: relative;

    &-reset {
      position: absolute;
      right: -.25rem;
      top: -.25rem;
      z-index: 8000;
      line-height: 1.25rem;
      padding: .25rem;
      border-radius: .25rem;
      display: flex;
      transition: all .2s ease-out;

      svg {
        fill: $gray;
        padding-right: 0;
      }

      &:hover {
        background-color: rgba($black,.1);
        color: $red;
        svg {
          fill: $red !important;
        }
      }
    }

    &-icon {
      margin-right: .5rem;
      // margin-top: .45rem;
    }

    code {
      background-color: rgba(0,0,0,.075);
      // color: rgba(255,255,255,.5);
      padding: 0 .25rem;
      margin-top: .25rem;
      border-radius: .25rem;
      margin-top: .25rem;
      display: inline-block;
      line-height: 1.25rem;
    }

    div {

      &:nth-child(1) {
        font-weight: 600;
        color: $black;
        font-size: .875rem;
        transition: all .2s ease-out;
      }
    }
  }
}
.error-panel-enter,
.error-panel-appear {
  opacity: 0;
  transform: translate(-50%, 2rem);
}
.error-panel-enter-active,
.error-panel-appear-active {
  opacity: 1;
  transform: translate(-50%, 0%);
  transition: all 150ms ease-out;
}
.error-panel-exit {
  opacity: 1;
  transform: translate(-50%, 0%);
}
.error-panel-exit-active {
  opacity: 0;
  transform: translate(-50%, 2rem);
  transition: all 150ms ease-out;
}

.header-errors-enter,
.header-errors-appear {
  opacity: 0;
  transform: translate(0%, -50%);
}
.header-errors-enter-active,
.header-errors-appear-active {
  opacity: 1;
  transform: translate(0%, 0%);
  transition: all 250ms ease-out;
}
.header-errors-exit {
  opacity: 1;
  transform: translate(0%, 0%);
}
.header-errors-exit-active {
  opacity: 0;
  transform: translate(0%, -50%);
  transition: all 250ms ease-out;
}
