
.functions {
  .fixed-column {

    @media screen and (min-width: 1280px) {
      max-width: 1280px;
    }

    .columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 4rem;
      grid-row-gap: 2rem;
      max-width: 100%;
      margin: 2rem auto;

      &-section {
        // width: 33.33%;
        .row-label {
          min-width: 40%;
          padding-right: 0;
        }

        .row-input {
          min-width: 60%;
        }

        input[type="number"] {
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
}
