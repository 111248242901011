@import '../../scss/variables';

table {
  width: 100%;
}
tbody {
  border-spacing: 0;
}
th {
  font-size: .75rem;
  color: $gray;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
  position: sticky;
  padding-right: 1rem;
  padding-left: .5rem;
  top: 8rem;
  height: 1.5rem;
  background-color: white;
  z-index: 1000;
  border-bottom: $border;
  border-width: .125rem;
  padding-bottom: .75rem;
  padding-top: .5rem;
  vertical-align: bottom;
  white-space: pre-wrap;
}
td {
  padding: .5rem .5rem .5rem .5rem;
  border-spacing: 0;
  border-bottom: $border;
  height: 2rem;
  color: $gray;
  min-width: 6.25rem;

  &.spacer {
    height: 1rem;
    padding: 0;
    background-color: rgba($black,.03);
  }

  &.row-label {
    padding-right: 2rem;
    color: $black;
    background-color: rgba($black,.03);
    border-right: $border;
  }

  input[type=number] {
    min-width: 4.5rem;
    width: 6.25rem;
  }

  select {
    min-width: 6.25rem;
  }
}
